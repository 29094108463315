import classNames from "classnames";
import { inject } from "mobx-react";

import { ResponsiveImage } from "../../../../core/components/ResponsiveImage.component";
import type { FeedResultFragment } from "@ihr-radioedit/inferno-webapi";
import { trimText } from "../../../../core/lib/utilities";
import { formatImage } from "@ihr-radioedit/inferno-core";
import { MagicLink } from "../../../../core/ui";
import { CoastFeedItemType, FULL_DATE_ITEM_TYPES, getCoastFeedImg } from "../../lib/coastFeed";
import { CoastLinkedGuests } from "../CoastLinkedGuests.component";
import { CoastLinkedHost } from "../CoastLinkedHost.component";
import { CoastShowDate } from "./CoastShowDate.component";
import { isPublishRecord, RESPONSIVE_IMAGE, reverseRoute } from "@inferno/renderer-shared-core";
import type { Store } from "@inferno/renderer-shared-core";

export interface CoastFeedShowItemProps {
  item: FeedResultFragment;
  type: CoastFeedItemType;
  store?: Store;
}

export const CoastFeedShowItem = inject("store")(({ item, store, type }: CoastFeedShowItemProps) => {
  if (!store || !isPublishRecord(item.record)) {
    return null;
  }

  const { env } = store;
  const { slug, payload } = item.record;
  const { summary } = payload;

  const thumb = formatImage(summary.image.url, env.IMAGE_HOST);
  const { imgSrc, srcset } = getCoastFeedImg(thumb, type, { quality: 80 });

  const showUrl = reverseRoute(store.site, "shows_detail", { slug }) || "#";
  const isShortDate = !FULL_DATE_ITEM_TYPES.has(type);

  const figcaptionClass = classNames({ "short-date": isShortDate });

  return (
    <div className="coast-feed-item">
      <MagicLink to={showUrl} context={slug} className="item-thumb-container">
        <figure className="item-thumb">
          <ResponsiveImage
            alt={summary.image.alt_text || summary.title}
            src={imgSrc}
            srcset={srcset}
            initialWidth={RESPONSIVE_IMAGE.responsiveImageInitialWidth}
            initialHeight={RESPONSIVE_IMAGE.responsiveImageInitialHeight}
          />
          <figcaption className={figcaptionClass}>
            <CoastShowDate item={item.record} isShortDate={isShortDate} store={store} />
            <span className="item-title">{summary.title}</span>
          </figcaption>
        </figure>
      </MagicLink>

      {type !== CoastFeedItemType.ART_BELL_VAULT ? (
        <section className="item-links">
          <CoastLinkedHost titleOnSameLine={true} item={item.record} />
          <CoastLinkedGuests titleOnSameLine={true} item={item.record} guestImage={false} />
        </section>
      ) : null}

      <section className="item-summary">{trimText(summary.description, 280)}</section>
    </div>
  );
});

export default CoastFeedShowItem;
